<template>
    <div class="row">
            <span class="col-sx-12 col-sm-12 col-md-12 d-sm-block d-md-block d-sx-block d-lg-none questionListBtn" v-if="selectedquestion" @click="openNav()">&#9776; Questions</span>
        <div id="mySidenav" class="questionList col-lg-4 col-xl-3 col-md-12 col-sx-12 col-sm-12" :class="{'sidenav':selectedquestion}">
            <div class="questionList col-sx-12 col-sm-12 col-md-12 d-sm-block d-sx-block d-md-block d-lg-none d-md-none pb-3 pt-3 mobileQuestTitle" >
                <a v-if="selectedquestion" class="closebtn" @click="closeNav()">&times;</a>
            <h4>Reading：Fill in the Blanks
                    </h4>
            </div>
            <app-IntervalList ref="intervalList" :questionLists="questionLists"/>
        </div>
        <div class="questonhelp col-lg-8 col-xl-9 d-none d-lg-block" v-if="!selectedquestion">
            <h4 class="text-center mb-4 fancy"><span>Reading：Fill in the Blanks</span></h4>             
            <h5 class="mb-1">Introduction</h5>
            <p class="para mb-2">You'll get text with some words missing. Drag words from the box to the appropriate place in the text. To undo an answer choice, drag the word back to the box.</p>
            <h5 class="mb-1">Skills assessed</h5>
            <p class="heading mb-2">Reading</p>
            <h5 class="mb-1">Number of questions</h5>
            <p class="para mb-2"> 4 - 5 </p>
            <template v-if="user_type == 'premium'">
            <h5 class="mb-1">KEY POINTS</h5>
            <ul class="ml-5">
                <li>You will be presented with a passage of text up to 80 words on your screen with several blanks. Just below the text, you’ll have several words. You need to drag and drop a proper word in a proper blank. You will get point for each correctly placed word.</li>
                <li>Understand the sentence and pick the words accordingly.</li>
                <li>Use your knowledge of grammar to help you select the correct word.</li>
                <li>Using collocation can help you recognize the correct word for each blank.</li>
            </ul>
            </template>
            <app-ReadingExtraInfo />
        </div>
        <div class="questiondesc col-lg-8 col-xl-9 col-md-12 col-sm-12 col-sx-12" :class="{'col-12':(questId != 0)}" v-if="selectedquestion">
            <h4>Reading：Fill in the Blanks</h4>
            <p class="questHeading">In the text below some words are missing. Drag words from the box below to the appropriate place in the text. To undo an answer choice, drag the word back to the box below the text.</p>
            <div class="row mb-2">
            <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 titleDiv">
                <div class="prepare" ><p>Time </p><app-Timer ref="timer" :increment="true"/></div>
            </div>
            <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 text-right pr-0" >
            <app-QuestionStatus ref="QuestionResetStatus" :selectedQuestion="selectedquestion" :question_id="selectedquestion.question_id" :idName="'question_id'" :tableName="'tblFillinBlankQuestion'"/>
            </div>
            </div>
            
            <p class="questdesc" v-html="selectedquestion.question_desc"></p>
            <div id="qlist2">
                <div :id="'dragitem'+item.answer_id+'-container'" class="drag-container" v-for="item in answerLists" :key="item.answer_id">
                <div :id="'dragitem'+item.answer_id" class="qitem2 answerDiv" >
                    {{item.answer_desc}}
                </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12 text-left mt-4" style="background: #001848;" v-if="this.userAnswer.answers">
                    <p style="padding-left:0px!important;color:#fff;"><strong>Your Score:   <span class="pl-1">{{score_count}}</span>/{{correctAnswer.length}}</strong></p>
                </div>
                <div class="col-lg-12 col-xl-6 col-md-12 text-left col-sm-12 questBtnDiv mt-5 pl-0">
                    <b-button class="mr-2 pl-4 pr-4" variant="outline-info" @click="submitanswer()">Submit</b-button>
                    <b-button variant="outline-warning" class="mr-2 pl-4 pr-4" @click="redoQuest()">Re-do</b-button>
                    <b-button variant="outline-dark" class="mr-2 pl-4 pr-4" @click="showModal()">Dict Mode</b-button>
                    <toggle-button :width="75" :height="28" v-model="checkAnswerFlag" :value="false" :color="{checked: '#104e94', unchecked: '#e9ecef', disabled: '#CCCCCC'}" :speed="500" :sync="true" :labels="{checked: 'Answer', unchecked: 'Answer'}"/>
                </div>
                <div class="col-6 text-right mt-3 pl-0 d-none d-xl-block mt-5">
                    <div class="row">
                        <div class="col-2 p-0">
                             <b-button :disabled="currentQuestion <= 1" @click="$refs.intervalList.changeQuestion('prev')" variant="outline-info" class="mr-2 prevQuestion pl-3 pr-3">Prev</b-button>
                        </div>
                        <div class="col-8 p-0">
                            <b-input-group>
                                <b-input-group-prepend>
                                    <b-icon shift-v="1.2" scale="1.3" icon="search"></b-icon>
                                </b-input-group-prepend>
                                <b-form-input v-model="searchKey" type="search" placeholder="Question title"></b-form-input>
                                <b-input-group-append>
                                <b-button @click="searchQuestion()" variant="outline-secondary">Search</b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </div>
                        <div class="col-2 p-0 text-left">
                             <!-- <b-button :disabled="currentQuestion <= 1" @click="$refs.intervalList.changeQuestion('prev')" class="mr-2 prevQuestion pl-3 pr-3">Prev Quest</b-button> -->
                        <b-button @click="$refs.intervalList.changeQuestion('next')" variant="outline-info" class="ml-2 nextQuestion pl-3 pr-3">Next</b-button>
                        </div>
                    </div>
                </div>
                <div class="col-12 text-center mt-4" v-if="checkAnswerFlag">
                <h4 class="decorated" style="text-transform:capitalize;"><span>Answer</span></h4>
                    <div class="col-12 mb-2 mt-2 results text-left" >
                    <h5 style="text-transform:capitalize;">Transcript:- </h5>
                    <p style="display:block;padding-bottom: 0px !important;">
                        <ul >
                            <li v-for="item in actualAnswer" :key="item.answer_id" class="rightAnswer"><strong> {{item.answer_desc}}</strong></li>
                        </ul>
                    </p>
                    </div>
                </div>
            </div>

            <!-- <div class="row">
                <div class="col-12  mb-2 mt-2 results" v-if="checkAnswerFlag == true">
                    <h6>Result:- </h6>
                    <p><strong>Your result is {{this.userAnswer.user_score}} out of {{correctAnswer.length}}</strong></p>
                </div>
                <div class="col-12" v-if="correctAnswer != null">
                    <ul >
                        <li v-for="item in correctAnswer" :key="item.answer_id" class="rightAnswer"><strong> {{item.answer_desc}}</strong></li>
                    </ul>
                </div>
                <div class="col-12 text-center">
                    <b-button :disabled="currentQuestion <= 1" @click="$refs.intervalList.changeQuestion('prev')" class="mr-5 prevQuestion">Prev Question</b-button>
                    <b-button variant="success" @click="submitanswer()">Find Answer & Marks</b-button>
                    <b-button @click="$refs.intervalList.changeQuestion('next')" class="ml-5 nextQuestion">Next Question</b-button>

                </div>
            </div> -->
        </div> 
        <b-modal ref="search-Result" size="lg" scrollable centered hide-footer title="Search Result" ok-only ok-title="Cancel" ok-variant="secondary">
             <b-container v-if="searchKey!='' | searchQuestionList!=null"><app-IntervalList name="search" :popup="true" :parentFunctionCalling="getQuestionDetail" :questionLists="searchQuestionList" :questionId="'question_id'"/></b-container>
        </b-modal>
        <b-modal id="dictionery-Modal" size="lg" scrollable centered hide-footer hide-header @hidden="hideModal" ok-only ok-title="Cancel" ok-variant="secondary">
         <b-container class="" v-if="selectedquestion!=null">
              <app-dictTemp :templatePara="dictText" :title="modelTitle"/>
         </b-container>
        </b-modal>
        <div class="col-12 mt-5 d-none d-lg-block" v-if="selectedquestion!=null">
            <app-DiscussionTab ref="discussionTab" :question_id="currentQuestion" :tableName="'tblUserFillinBlankAnswer'"/>
        </div>
    </div>
</template> 
<script>
import { mapActions,mapGetters } from 'vuex'
import Timer from '../reuseable/timer'
import $ from "jquery";
import 'jquery-ui-dist/jquery-ui';
import IntervalList from '../reuseable/intervalList'
import QuestionStatus from '../reuseable/questStatus'
import dictTemplate from '../dictionerymode/dictioneryTemplate'
import DiscussionTab from '../reuseable/discussionTab'
import ReadingExtraInfo from '../reuseable/readingInformation.vue'
//import LocalStorageService from '../../store/LocalStorageService';
export default {
    components: {
            'app-Timer':Timer,
            'app-IntervalList': IntervalList,
            'app-dictTemp': dictTemplate,
            'app-QuestionStatus':QuestionStatus,
            'app-DiscussionTab':DiscussionTab,
            'app-ReadingExtraInfo':ReadingExtraInfo
    },
    data(){
        return{
            questionLists:{
                type: Object,
                required: true
            },
            selectedquestion: null,
            answerLists:{
                type: Object,
                required: true
            },
            correctAnswer:null,
            userAnswerResponse:null,
            userAnswer:{
                user_id:0,
                question_id:0,
                answers:null,
                url:'',
                user_score:'',
                mock_test_id:0
            },
            score_count:0,
            options:[],
            checkAnswerFlag:false,
            user_type:'Free',
            currentQuestion:0,
            actualAnswer:null,
            searchKey:'',
            searchQuestionList:null,
            isShowModal: false,
             modelTitle:'heading',
            dictText:''
        }
    },
    methods:{
        ...mapActions({
            questionservice: 'readingmodule/fetchQuestion',
            saveAnswer: 'readingmodule/saveAnswer'
        }),
        ...mapGetters({
            getQuestionList: 'readingmodule/questions',
            answerResponse: 'readingmodule/AnswerResponse'
        }),
        redoQuest(){
            this.getQuestionDetail(this.currentQuestion)
        },
        openNav(){
            document.getElementById("mySidenav").style.width = "100%";
        },
        closeNav(){
            document.getElementById("mySidenav").style.width = "0";
        },
        reset(){
            this.userAnswer={
                question_id:0,
                user_id:this.$ls.get('user').user_id,
                answers:null,
                url:'',
                user_score:''
            }
            this.score_count = 0;
            this.checkAnswerFlag = false;
            this.correctAnswer = null
            this.actualAnswer=null
        },
        showModal() {
            
            for(var index = 1; index <= 20; index++){
               this.dictText = this.dictText.replace('#blank' + index + '#', '')
            }
            this.isShowModal = true;
            this.$root.$emit('bv::show::modal', 'dictionery-Modal')
            this.$refs.timer.pauseTime()
            },
        hideModal() {
            this.isShowModal = false
            this.$refs.timer.resumeTime()
            },
        searchQuestion(){
            const key=this.searchKey.trim().toLowerCase()
            const searchedQuestion= this.questionLists.filter((item)=>{
                return item.title.toLowerCase().includes(key)
            });
            this.searchKey = '';
            this.$refs.timer.pauseTime()
            ////console.log(searchedQuestion)
            if(searchedQuestion.length>0){
                if(searchedQuestion.length>1){
                   //console.log(searchedQuestion)
                   this.searchQuestionList=searchedQuestion
                   this.$refs['search-Result'].show()
                }
                else{
                    //console.log(searchedQuestion)
                    return this.getQuestionDetail(searchedQuestion[0].question_id)
                }
            }
            else{
                return this.$alert("No record found with the given title", "Search Result");
            }
        },
        updateSelectQuestionStatus(question,id){
            ////console.log(this.selectedquestion)
            this.selectedquestion = question
            const currectQuestionIndex=this.questionLists.findIndex(element=>
            element.question_id==id)
            this.questionLists[currectQuestionIndex]=this.selectedquestion
            ////console.log(question)
        },
        getQuestionDetail(questionId){
             if(questionId==0) return this.selectedquestion=null
            if(this.$refs['search-Result'].isVisible) this.$refs['search-Result'].hide()
            this.currentQuestion=questionId
            this.reset()
            this.userAnswer.question_id = questionId;
            this.removeClasses()
            this.selectedquestion = this.questionLists.filter(function (item) {
                    return item.question_id == questionId;
                })[0];
            this.dictText=this.selectedquestion.question_desc
            if(this.$refs.QuestionResetStatus)
            this.$refs.QuestionResetStatus.resetQuestionDetails(this.selectedquestion,questionId)
            for(var index = 1; index <= 20; index++){
               this.selectedquestion.question_desc = this.selectedquestion.question_desc.replace('#blank' + index + '#', '<div class="droppable-element" style="width:150px;height:30px;display:inline-block;border:1px solid #ccc;margin: 2px 5px 0px 5px;border-radius: 3px;background: #f9f9f9;color:black;"></div>')     
            }
            this.modelTitle = this.selectedquestion.title
            //console.log(this.selectedquestion)
            this.questionservice('configblankoptions/id='+questionId).then(()=>{
                this.answerLists = this.getQuestionList().data
                this.actualAnswer = this.answerLists.filter(function(item){
                return item.correct_val_ind == 'T'
                    })
            //console.log(this.answerLists)
            }).then(()=>{
                this.makedraggable()
                this.droping()
                this.$nextTick(function(){
                this.$refs.discussionTab.updateQuestionId(questionId)
                this.$refs.timer.setTime(0)
                })
                //console.log($('#qlist2').text())
            })
            
        },
        makedraggable() {
            $(".qitem2").draggable({
                    "revert": "invalid"
            });
        },
        droping(){
             $(".droppable-element").droppable({
                "accept": ".qitem2",
                "drop": function(event, ui) {
                    if ($(this).find(".qitem2").length) {
                        var $presentChild = $(this).find(".qitem2"),
                            currChildId = $presentChild.attr("id"),
                            $currChildContainer = $("#" + currChildId + "-container");                  
                    $currChildContainer.append($presentChild);
                    $presentChild.removeAttr("style");
                    //this.makedraggable();
                     $(".qitem2").draggable({
                            "revert": "invalid"
                    });
                    }
                    
                    $(ui.draggable).clone().appendTo(this).removeAttr("style");
                    $(ui.draggable).remove();
                
                }
            })
            $.ui.draggable.prototype.destroy;// = function (ul, item) { };
        },
        async checkAnswer(){
            var vm = this;
            this.checkAnswerFlag = true;
            this.score_count = '';
            vm.removeClasses()
            vm.correctAnswer = vm.answerLists.filter(function(item){
                return item.correct_val_ind == 'T'
            })
            const correct = vm.correctAnswer
            $('.droppable-element').each(function(index,item){
                //console.log(index)
                //console.log($(item).find('div'))
                if($(item).find('div').length >0){
                    if(correct[index].answer_desc != item.childNodes[0].innerText)
                    {
                        $(item).addClass('wrongSelection wrongAnswer')
                    }
                    else{
                        vm.score_count++
                        $(item).addClass('rightSection rightAnswer')
                    }
                    //console.log(item.childNodes[0].innerText)
                }
                else{
                     $(item).addClass('wrongSelection')
                }
            })

            
            vm.userAnswer.user_score ='Your Score: '+vm.score_count+'/'+ vm.correctAnswer.length;
            ////console.log(vm.userAnswer.user_score)
        },
        async submitanswer() {
            await this.checkAnswer();
            var vm = this;
            $('.droppable-element').each(function(index,item){
                if($(item).find('div').length >0){
                    const tempCt= {
                    answer_desc: item.childNodes[0].innerText
                }
                vm.options.push(tempCt);
                }
            });
            var userdata =this.$ls.get('user')
            this.userAnswer.user_id = userdata.user_id
            this.userAnswer.url = 'userblankanswers/createblankanswer';
            this.userAnswer.answers = this.options;
            this.userAnswer.mock_test_id=0
            //console.log(this.userAnswer);
            this.saveAnswer(this.userAnswer).then(()=>{
                    //console.log(this.answerResponse())
                    vm.userAnswerResponse = this.answerResponse().data
                    //alert('Data has been saved successfully.');
                    //vm.resetAll();
                });
                
                
        },
        resetAll(){
            $('.droppable-element').each(function(index,item){
                if($(item).find('div').length >0){
                    item.childNodes[0].innerText = '';
                }
            });
            this.score_count = 0;
            this.checkAnswerFlag = false;
            this.userAnswer={
                user_id:this.$ls.get('user').user_id,
                question_id:0,
                answers:null,
                url:'',
                user_score: '',
                mock_test_id:0
            }

        },
        removeClasses(){
            $('div.wrongSelection').removeClass('wrongSelection wrongAnswer')
            $('div.rightSection').removeClass('rightSection rightAnswer')
        },
        timerEnd(){
            //console.log("end of time")
        }
    },
    beforeMount(){
        this.questionservice('configfillingblanks/getallfillinblanks').then(()=>{
            this.questionLists = this.getQuestionList().data
            //var userdata = LocalStorageService.getUser()
            var userdata =this.$ls.get('user')
            this.userAnswer.user_id = userdata.user_id
            this.user_type = userdata.user_type
            //console.log(this.questionLists)
        })
    },
    watch:{
        checkAnswerFlag:{
            handler(){
                if(this.checkAnswerFlag){
                    //console.log(this.actualAnswer)
                    var vm = this
                    $( ".ui-droppable" ).before(function(index) {
                        if(this.firstChild!=null){
                             if(this.firstChild.innerText == vm.actualAnswer[index].answer_desc)
                            {
                                return "<span class='showAnswer'> ( Answer:- " + vm.actualAnswer[index].answer_desc + ")</span>";
                            }
                            else{
                                return "<span class='showAnswer wrong'> ( Answer:- " + vm.actualAnswer[index].answer_desc + ")</span>";
                            }
                        }
                        else{
                            return "<span class='showAnswer wrong'> ( Answer:- " + vm.actualAnswer[index].answer_desc + ")</span>";
                        }
                       
                    });
                }
                else{
                    $('.showAnswer').remove()
                }
            }
        }
    }
}
</script>
<style>
.showAnswer
{
    color: #17b856;
font-weight: bold;
}
.showAnswer.wrong
{
    color: #f44336;
}
</style>
<style scoped>
p{
    line-height: 25px;
}
#qlist2
{
    padding: 10px 19px;
    border: 1px solid #ccc;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    background: #f9f9f9;
}
.drag-container
{
    display: inline;
}
.answerDiv
{
    display: inline-block;
    display: inline-block;
    padding-top: 5px;
    padding-right: 7px;
    padding-bottom: 5px;
    padding-left: 7px;
    cursor: pointer;
    min-height: 25px;
    background: #3eb2e3;
    margin: 0px 5px;
    position: relative;
    top: 0;
    left: 0;
    color: white;
    border-radius: 3px;
}
.droppable-element .answerDiv
{
    background: none;
    padding: 0 1px;
    color: black;
        line-height: 10px;
}

.text-center button{
    margin-right:30px
}
li.rightAnswer
{
    list-style: decimal;
}
.questdesc
{
    margin-bottom: 0px;
    border: 1px solid #ccc;
    background: white;
    border-bottom: 0px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    line-height: 35px!important;
}
</style>